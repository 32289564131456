import {action, configure, makeObservable, observable} from 'mobx';
import isEmpty from 'is-empty';
import reachGoalYM from "@/Helpers/ReachGoalYM";
import reachGoalGA from "@/Helpers/ReachGoalGA";
import {Inertia} from "@inertiajs/inertia";
import axios from "axios";
import {declOfNum} from "@/Helpers/Function";

export default class BasketStore {
    @observable basketCount = 0;
    @observable basketProducts = [];
    @observable products = [];

    @observable textOrderRegistration = 'Перейти к оплате';
    @observable textOrderRegistrationOneClick = 'Оформить заказ на';
    @observable statusSbpMessage = '';

    // Бонусы
    @observable bonuses = {
        totalBonusesAdd: 0,
        productsBonusesAdd: 0,
        bonusesBalance: 0,
        maximumSubtractBonuses: 0,
        subtractBonuses: 0,
    };
    @observable bonusesValues = 0;
    @observable subtractBonuses = null; // Сумма бонусов, подлежащая списанию
    @observable bonusesMsg = '';

    // Промокод
    @observable promo_id = null; //Номер промокода
    @observable promo_sum = 0; //Сумма промокода если применился
    promo_type = null; //Тип промокода, сумма или процент
    @observable promoCodeValues = '';
    @observable promoCodeErrMsg = '';
    @observable promoCodeMsg = '';

    // Процент скидки при онлайн оплате
    @observable paymentOnlineDiscount = 0;
    // Процент скидки при оплате через СБП
    @observable paymentOnlineDiscountSbp = 0;
    // Процент скидки при оплате Яндекс Пэй
    @observable paymentOnlineDiscountYandexPay = 0;
    // Процент скидки при оплате Яндекс Сплит
    @observable paymentOnlineDiscountYandexSplit = 0;
    // Процент скидки при оплате в кредит
    @observable paymentOnlineDiscountCreditSber = 0;
    // Процент скидки при оплате в рассрочку
    @observable paymentOnlineDiscountInstallmentSber = 0;
    // Процент скидки при оплате долями
    @observable paymentOnlineDiscountDolyame = 0;
    // Количество бонусов за онлайн оплату
    @observable paymentOnlineBonusesAdd = 0;
    // Акции один в подарок
    saleOneCount = 0;
    textWarning = null;
    // Акция подарок - эксклюзивный питомец
    @observable saleGift = false;
    // Акция подарок - эксклюзивный питомец (данные для акции)
    @observable saleGiftData = [];

    // Заказы ожидающие оплату
    @observable dataUnpaidOrders = [];

    // Текущий способ доставки (определяется на сервере)
    @observable currentShippingMethod = 'pickup_point_delivery';
    // Способ доставки (По умолчанию ПВЗ)
    @observable shippingMethod = 'pickup_point_delivery';
    // По умолчанию оплата онлайн
    @observable valuePaymentMethod = 'online';
    @observable valuePassportChange = 'Российский';
    @observable flagPassportForm = true;
    @observable flagPassport = false;
    @observable flagPassportOneClick = false;

    @observable onlineDiscount = 0;
    @observable onlineDiscountText = '';
    // Окончательная сумма для оформления заказа
    @observable finalSum = 0;
    // Сумма за вычетом бонусов, промокода и т.д.
    @observable finalSumStart = 0;
    // Не изменяемые данные, рассчитываются на сервере
    finalSumNotDiscounts = 0; // Общая сумма всех позиций в корзине без учета скидок по акциям
    finalSumWithDiscounts = 0; // Общая сумма всех позиций в корзине за вычетом скидок по акциям
    finalSumDiscounts = 0; // Общая сумма скидок

    // Ошибки сервера
    @observable serverErrorOrders = '';
    @observable serverErrorOrderProcessing = '';
    @observable load = false;
    @observable formLoad = '';
    @observable sbpUrl = '';
    @observable loadSbp = '';
    @observable loadCount = false;
    // @observable serverMessage = '';

    // Заказы
    @observable dialogBasketOpen = false;
    @observable errorOrderMessage = 'Что то пошло не так...';

    // Тип доставки
    @observable deliveryValue = 'standardDelivery';
    // Ожидаемая дата доставки
    @observable deliveryDate = null;
    // Сумма доставки
    @observable deliverySum = 0;

    // Даты доставки, не изменяемые данные, рассчитываются на сервере
    minDate = null;
    minDateExpress = null;
    // Сумма экспресс доставки, не изменяемые данные, рассчитываются на сервере
    deliveryAmountExpress = 0;
    deliveryAmount = 0;
    deliveryFreeSum = 0;
    // УИД склада отгрузки
    guidShop = null;
    // Возможно только оплата онлайн
    paymentOnlyOnline = false;

    // API проверка промокода
    @observable basketPromoCodeLoader = '';
    @observable basketBonusesLoader = '';

    // Номер введенного сертификата
    @observable certificateNumber = '';
    // Примененные сертификаты
    @observable certificateData = [];
    // Ошибки применения сертификатов
    @observable certificateErrorMessage = '';
    // Итоговая сумма примененных сертификатов
    @observable certificateSum = 0;

    // Адрес доставки отмеченный как текущий в базе
    @observable currentDeliveryAddress = {};
    // Адрес доставки выбранный в модальном окне в корзине
    @observable selectedDeliveryAddress = {};
    // Адрес доставки для отображения в модальном окне в корзине
    @observable visualAddress = '';
    // Адрес в модалке выбора pvz or brandShop
    @observable deliveryInformationModalAddress = '';

    constructor(rootStore) {
        makeObservable(this);
        configure({
            enforceActions: "never",
        });
        this.rootStore = rootStore;
    }

    basketStart = (props) => {
        const {basketData = {}, config = {}} = props;
        const {basketSettings = {}} = config;
        this.basketSettings = basketSettings;

        const {
            dataUnpaidOrders,
            saleHelp,
            bonuses,
            promoCode,
            certificateData,
            products,
            additionalData = {},
        } = basketData;

        const {
            finalSumNotDiscounts = 0,
            finalSumWithDiscounts = 0,
            finalSumDiscounts = 0,
            minDate = null,
            minDateExpress = null,
            deliveryAmountExpress = 0,
            deliveryAmount = 0,
            deliveryFreeSum = 0,
            guidShop = null,
            currentDeliveryAddress = {},
            paymentOnlyOnline = false,
        } = additionalData;

        // 1 сценарий, все не оплаченные онлайн заказы
        this.dataUnpaidOrders = dataUnpaidOrders;

        // 2 сценарий, данные по корзине
        // Бонусы
        this.bonuses = bonuses;
        this.bonusesValues = bonuses.subtractBonuses;

        // Промокод
        this.promo_id = promoCode.promo_id;
        this.promo_sum = promoCode.promo_sum;
        this.promo_type = promoCode.promo_type;
        this.promoCodeMsg = promoCode.message;
        this.promoCodeErrMsg = promoCode.errorMessage;

        // Если корзина пуста, очистим введенный ранее промокод
        if (products.length === 0) {
            this.promoCodeValues = '';
        }

        // Данные по сертификатам
        this.certificateData = certificateData;

        // скидки при онлайн оплате
        this.paymentOnlineDiscount = saleHelp.onlineDiscount;
        this.paymentOnlineDiscountSbp = saleHelp.onlineDiscountSbp;
        this.paymentOnlineDiscountCreditSber = saleHelp.onlineDiscountCreditSber;
        this.paymentOnlineDiscountInstallmentSber = saleHelp.onlineDiscountInstallmentSber;
        this.paymentOnlineDiscountDolyame = saleHelp.onlineDiscountDolyame;
        this.paymentOnlineDiscountYandexPay = saleHelp.onlineDiscountYandexPay;
        this.paymentOnlineDiscountYandexSplit = saleHelp.onlineDiscountYandexSplit;
        this.paymentOnlineBonusesAdd = saleHelp.onlineBonusesAdd;

        // акция 2=1
        this.saleOneCount = saleHelp.saleOneCount;
        this.textWarning = saleHelp.textWarning;

        // акция эксклюзивный питомец
        this.saleGift = saleHelp.saleGift;
        this.saleGiftData = saleHelp.saleGiftData;

        this.finalSum = finalSumWithDiscounts;

        // Не изменяемые данные
        this.finalSumNotDiscounts = finalSumNotDiscounts;
        this.finalSumWithDiscounts = finalSumWithDiscounts;
        this.finalSumDiscounts = finalSumDiscounts;

        // Даты доставки и стоимость
        this.minDate = minDate;
        this.minDateExpress = minDateExpress;
        this.deliveryAmount = deliveryAmount;
        this.deliveryAmountExpress = deliveryAmountExpress;
        this.deliveryFreeSum = deliveryFreeSum;
        // УИД склада отгрузки
        this.guidShop = guidShop;
        this.paymentOnlyOnline = paymentOnlyOnline;

        this.products = products;

        // Рассчитываем дату доставки и стоимость
        if (this.deliveryValue === 'expressDelivery' && this.deliveryAmountExpress !== 0) {
            this.deliveryDate = this.minDateExpress;
            this.deliverySum = this.deliveryAmountExpress;
        } else {
            this.deliveryValue = 'standardDelivery';
            this.deliveryDate = this.minDate;
            this.deliverySum = this.deliveryAmount;
        }

        this.currentDeliveryAddress = currentDeliveryAddress ?? {};
        // Если есть адрес доставки
        if (this.currentDeliveryAddress?.delivery_type === 'pvz') {
            this.currentShippingMethod = 'pickup_point_delivery';
        } else if (this.currentDeliveryAddress?.delivery_type === 'brandShop') {
            this.currentShippingMethod = 'pickup_store';
        } else if (this.currentDeliveryAddress?.delivery_type === 'courier') {
            this.currentShippingMethod = 'courier_delivery';
        }
        // Контроль способов оплаты в зависимости от выбранного адреса доставки
        this.controlPaymentMethods(this.currentDeliveryAddress);

        this.bonusesControl();
        // this.isFittingControl();
        this.calculationPrice();

        this.basketPromoCodeLoader = '';
        this.basketBonusesLoader = '';
    };

    /**
     * Запрос на пересчет всей корзины, 1 этап
     */
    getBasket = () => {
        let data = {
            paymentMethod: this.valuePaymentMethod,
            // flagBonusesPromoCod: this.flagBonusesPromoCod,
            promoCodeValue: this.promo_id,
            certificateData: this.certificateData,
            // deliveryData: this.deliveryData,
            bonuses: this.subtractBonuses,
        };

        Inertia.post(
            '/basket',
            data,
            {
                preserveScroll: true,
            }
        );
    };

    /**
     * Запрос на пересчет всей корзины, 2 этап
     */
    getBasketGoToCheckout = () => {
        let data = {
            paymentMethod: this.valuePaymentMethod,
            // flagBonusesPromoCod: this.flagBonusesPromoCod,
            promoCodeValue: this.promo_id,
            certificateData: this.certificateData,
            // deliveryData: this.deliveryData,
            bonuses: this.subtractBonuses,
        };

        Inertia.post(
            '/go-to-checkout',
            data,
            {
                preserveScroll: true,
            }
        );
    };

    basketProductsUpdate = (basketProducts) => {
        this.basketProducts = basketProducts;
        this.basketCount = 0;
        this.basketProducts.forEach((el) => {
            this.basketCount += Number(el.count);
        });
    };

    /**
     * Удаление изделия из корзины
     * @param prodId
     * @param dataId
     * @param type
     */
    deleteBasket = async (prodId = null, dataId = null, type = 'item') => {
        try {
            const response = await axios.post('/basket/delete', {
                idProduct: prodId,
                idProductData: dataId,
                type: type
            });

            const {success} = response.data;
            if (success.status) {
                this.getBasket();
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Добавление / изменение
     * @param product_id
     * @param productData_id
     * @param count
     * @param flag
     */
    @action addChange = async (product_id, productData_id, count = 1, flagSnackbar = true) => {
        try {
            const response = await axios.post('/basket/addChange', {
                idProduct: product_id,
                idProductData: productData_id,
                count: count,
                returnProduct: flagSnackbar
            });

            const {success = {}} = response.data
            const addSnackbarsStore = this.rootStore.addSnackbarsStore;
            if (success.status) {
                this.basketProductsUpdate(success.basketProducts);

                if (flagSnackbar) {
                    addSnackbarsStore.data = {
                        text: `Товар добавлен!`,
                        autoHide: 3000,
                        severity: 'success',
                        basket: true
                    };
                    reachGoalYM('add_basket');
                    reachGoalGA('add_basket');

                    const gtag_prod = success.products.map((el) => {
                        return {
                            'id': el.product_id,
                            'name': el.h1,
                            'price': el.price,
                            'brand': 'PLATINA',
                            'category': el.category,
                            'quantity': 1
                        };
                    });

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            'ecommerce': {
                                'currencyCode': 'RUB',
                                "add": {
                                    "products": gtag_prod
                                }
                            }
                        });
                    }

                    addSnackbarsStore.handleClickAddSnackbars();
                }

                if (location.pathname === '/basket') {
                    this.getBasket();
                }
            } else {
                addSnackbarsStore.data = {
                    text: 'Произошла ошибка при изменении товара!',
                    autoHide: 3000,
                    severity: 'success',
                    basket: false
                };
                addSnackbarsStore.handleClickAddSnackbars();
            }
        } catch (e) {
            console.log('e', e);
            this.loadCount = false;
            const addSnackbarsStore = this.rootStore.addSnackbarsStore;
            addSnackbarsStore.data = {
                text: `Произошла ошибка при изменении товара!`,
                autoHide: 3000,
                severity: 'success',
                basket: false
            };
            addSnackbarsStore.handleClickAddSnackbars();
        }
    };

    /**
     * Добавление / изменение набора
     * @param productsSet
     * @param flagSnackbar
     * @returns {Promise<void>}
     */
    @action addChangeSet = async (productsSet, flagSnackbar = true) => {
        try {
            const response = await axios.post('/basket/addChangeSet', {
                products: productsSet,
                returnProduct: flagSnackbar
            });

            const {success = {}} = response.data
            const addSnackbarsStore = this.rootStore.addSnackbarsStore;
            if (success.status) {
                this.basketProductsUpdate(success.basketProducts);

                if (flagSnackbar) {
                    addSnackbarsStore.data = {
                        text: `Товары добавлены!`,
                        autoHide: 3000,
                        severity: 'success',
                        basket: true
                    };
                    reachGoalYM('add_basket');
                    reachGoalGA('add_basket');

                    const gtag_prod = success.products.map((el) => {
                        return {
                            'id': el.product_id,
                            'name': el.h1,
                            'price': el.price,
                            'brand': 'PLATINA',
                            'category': el.category,
                            'quantity': 1
                        };
                    });

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            'ecommerce': {
                                'currencyCode': 'RUB',
                                "add": {
                                    "products": gtag_prod
                                }
                            }
                        });
                    }

                    addSnackbarsStore.handleClickAddSnackbars();
                }

                if (location.pathname === '/basket') {
                    this.getBasket();
                }
            } else {
                addSnackbarsStore.data = {
                    text: 'Произошла ошибка при изменении товаров!',
                    autoHide: 3000,
                    severity: 'success',
                    basket: false
                };
                addSnackbarsStore.handleClickAddSnackbars();
            }
        } catch (e) {
            console.log('e', e);
            this.loadCount = false;
            const addSnackbarsStore = this.rootStore.addSnackbarsStore;
            addSnackbarsStore.data = {
                text: `Произошла ошибка при изменении товаров!`,
                autoHide: 3000,
                severity: 'success',
                basket: false
            };
            addSnackbarsStore.handleClickAddSnackbars();
        }
    };

    /**
     * Меняем способ доставки (pvz, brandShop, courier)
     * @param type
     */
    @action changeShippingMethod(type) {
        try {
            this.currentShippingMethod = type;
            this.shippingMethod = type;
            this.visualAddress = '';

            this.selectedDeliveryAddress.operationMode = '';
            this.selectedDeliveryAddress.phone = '';
            this.selectedDeliveryAddress.howToGet = '';
        } catch (e) {
            console.log('e', e);
        }
    }

    /**
     * Определяем способ оплаты
     * @param event
     */
    @action handlePaymentMethodChange(event) {
        // Не меняем способ оплаты, если был применен сертификат и сумма к оплате составила 0 р
        if (this.certificateData.length !== 0 && this.finalSum === 0) {
            return;
        }

        this.valuePaymentMethod = event.target.value;
        this.getBasketGoToCheckout();
    }

    /**
     * Меняем форму для паспорта (Российский/Иностранный)
     * @param event
     */
    @action changePassportForm = (event) => {
        this.valuePassportChange = event.target.value;
        if (this.valuePassportChange.toLowerCase() === 'российский') {
            this.flagPassportForm = true;
        } else {
            this.flagPassportForm = false;
        }
    };

    /**
     * Расчет общего прайса
     */
    @action calculationPrice = () => {
        if (this.products.length === 0) {
            return false;
        }

        // Присваиваем стартовую сумму перед расчетами
        this.finalSum = this.finalSumWithDiscounts;

        // Вычитаем сумму промокода
        if (this.promo_sum !== 0) {
            this.finalSum = this.finalSum - this.promo_sum;
        }

        // Вычитаем сумму бонусов
        if (this.bonuses.subtractBonuses !== 0) {
            this.finalSum = this.finalSum - this.bonuses.subtractBonuses;
        }

        this.finalSumStart = this.finalSum;

        this.onlineAdd();
        this.passportControl();
    };

    /**
     * Пересчет скидок и итоговой суммы
     */
    @action onlineAdd = () => {
        if ((this.valuePaymentMethod === 'byCash' || this.valuePaymentMethod === 'bankCard') && (this.paymentOnlyOnline || this.certificateData.length > 0)) {
            this.valuePaymentMethod = 'online';
        }
        if (this.valuePaymentMethod === 'yandexSplit'
            && ((this.finalSumStart - Math.round(this.paymentOnlineDiscountYandexSplit * this.finalSumStart / 100)) < this.basketSettings.paymentYandexSplitMin
                || (this.finalSumStart - Math.round(this.paymentOnlineDiscountYandexSplit * this.finalSumStart / 100)) > this.basketSettings.paymentYandexSplitMax)) {
            this.valuePaymentMethod = 'online';
        }
        if (this.valuePaymentMethod === 'installment'
            && ((this.finalSumStart - Math.round(this.paymentOnlineDiscountInstallmentSber * this.finalSumStart / 100)) < this.basketSettings.paymentInstallmentMin
                || (this.finalSumStart - Math.round(this.paymentOnlineDiscountInstallmentSber * this.finalSumStart / 100)) > this.basketSettings.paymentInstallmentMax)) {
            this.valuePaymentMethod = 'online';
        }
        if (this.valuePaymentMethod === 'credit'
            && ((this.finalSumStart - Math.round(this.paymentOnlineDiscountCreditSber * this.finalSumStart / 100)) < this.basketSettings.paymentCreditMin
                || (this.finalSumStart - Math.round(this.paymentOnlineDiscountCreditSber * this.finalSumStart / 100)) > this.basketSettings.paymentCreditMax)) {
            this.valuePaymentMethod = 'online';
        }
        if (this.valuePaymentMethod === 'dolyame'
            && (this.finalSumStart - Math.round(this.paymentOnlineDiscountDolyame * this.finalSumStart / 100)) > this.basketSettings.paymentDolyameMax) {
            this.valuePaymentMethod = 'online';
        }

        if (this.valuePaymentMethod === 'online') {
            this.textOrderRegistration = 'Перейти к оплате';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscount * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате онлайн';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'certificate') {
            this.textOrderRegistration = 'Оформить заказ';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscount * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате онлайн';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd;
        } else if (this.valuePaymentMethod === 'installment') {
            this.textOrderRegistration = 'Оформить в рассрочку';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountInstallmentSber * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате в рассрочку';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'credit') {
            this.textOrderRegistration = 'Оформить в кредит';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountCreditSber * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате «Покупай со Сбером»';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'sbp') {
            this.textOrderRegistration = 'Перейти к оплате';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountSbp * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате через СБП';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'yandexPay') {
            this.textOrderRegistration = 'Перейти к оплате';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountYandexPay * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате Я.Пей';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'yandexSplit') {
            this.textOrderRegistration = 'Перейти к оплате';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountYandexSplit * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате Я.Сплит';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else if (this.valuePaymentMethod === 'dolyame') {
            this.textOrderRegistration = 'Оформить долями';
            this.onlineDiscount = Math.round(this.paymentOnlineDiscountDolyame * this.finalSumStart / 100);
            this.onlineDiscountText = 'Скидка при оплате долями';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd + this.paymentOnlineBonusesAdd;
        } else {
            this.textOrderRegistration = 'Оформить заказ';
            this.onlineDiscount = 0;
            this.onlineDiscountText = '';
            this.bonuses.totalBonusesAdd = this.bonuses.productsBonusesAdd;
        }
        this.finalSum = this.finalSumStart - this.onlineDiscount;

        this.finalSum = this.finalSum + this.deliverySum;

        // Применяем сертификат
        this.calculationCertificate();

        if (this.finalSum === 0 && this.valuePaymentMethod !== 'certificate') {
            this.valuePaymentMethod = 'certificate';
            this.onlineAdd();
        } else if (this.finalSum > 0 && this.valuePaymentMethod === 'certificate') {
            this.valuePaymentMethod = 'online';
            this.onlineAdd();
        }
    };

    /**
     * Показываем форму с паспортными данными
     */
    @action passportControl = () => {
        if ((this.valuePaymentMethod == 'online' || this.valuePaymentMethod == 'installment' || this.valuePaymentMethod == 'credit') && this.finalSum >= this.basketSettings.paymentOnline) {
            this.flagPassport = true;
        } else if (this.valuePaymentMethod == 'bankCard' && this.finalSum >= this.basketSettings.paymentBankCard) {
            this.flagPassport = true;
        } else if (this.valuePaymentMethod == 'byCash' && this.finalSum >= this.basketSettings.paymentByCash) {
            this.flagPassport = true;
        } else {
            this.flagPassport = false;
        }
    };

    /**
     * Показываем чек бокс с ФЗ №115
     */
    @action passportControlOneClick = () => {
        if (
            (this.rootStore.buyOneClickStore.valuePaymentMethodOneClick === 'online' || this.rootStore.buyOneClickStore.valuePaymentMethodOneClick === 'installment' || this.rootStore.buyOneClickStore.valuePaymentMethodOneClick === 'credit')
            && this.rootStore.productDetailStore.priceOneClickFinal >= this.basketSettings.paymentOnline
        ) {
            this.flagPassportOneClick = true;
        } else if (this.rootStore.buyOneClickStore.valuePaymentMethodOneClick == 'bankCard' && this.rootStore.productDetailStore.priceOneClickFinal >= this.basketSettings.paymentBankCard) {
            this.flagPassportOneClick = true;
        } else if (this.rootStore.buyOneClickStore.valuePaymentMethodOneClick == 'byCash' && this.rootStore.productDetailStore.priceOneClickFinal >= this.basketSettings.paymentByCash) {
            this.flagPassportOneClick = true;
        } else {
            this.flagPassportOneClick = false;
        }
    };

    /**
     * Открытие модалки "Ошибка при заказе"
     */
    @action openDialogBasket = () => {
        this.dialogBasketOpen = true;
    };

    /**
     * Закрытие модалки "Ошибка при заказе"
     */
    @action closeDialogBasket = () => {
        this.dialogBasketOpen = false;
        this.serverErrorOrders = '';
    };

    /**
     * Удаляем заказ из списка не оплаченных спустя 20 минут
     * @param id
     */
    @action deleteUnpaidOrders = (id) => {
        this.dataUnpaidOrders = this.dataUnpaidOrders.filter((el) => id !== el.id)
    };

    @action bonusesControl = () => {
        if (this.bonuses.maximumSubtractBonuses === 0) {
            if (this.bonuses.bonusesBalance === 0) {
                this.bonusesMsg = 'Нет бонусов для списания';
            } else {
                this.bonusesMsg = 'На счете ' + this.bonuses.bonusesBalance +
                    ' бонусов, но они недоступны для выбранных украшений';
            }
        } else {
            this.bonusesMsg = 'Можно списать ' + this.bonuses.maximumSubtractBonuses.toLocaleString('ru') +
                ' из ' + this.bonuses.bonusesBalance.toLocaleString('ru') + ' ' +
                declOfNum(this.bonuses.bonusesBalance, ['бонуса', 'бонусов', 'бонусов']);
        }
    };

    /**
     * Изменяем бонусы
     * @param e
     */
    @action bonusesChangeValue = (e) => {
        try {
            // Удаляем ведущие нули с помощью регулярного выражения
            this.bonusesValues = e.target.value.replace(/^0+/, '');
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Отправляем сумму бонусов для расчета списания введенную пользователем
     * @param changeFlag
     * @returns {boolean}
     */
    @action bonusesChange = (changeFlag = false) => {
        try {
            if (this.basketBonusesLoader === 'load') return false;

            this.basketBonusesLoader = 'load';
            this.bonusesValues = changeFlag ? 0 : Math.min(Number(this.bonusesValues), this.bonuses.maximumSubtractBonuses);

            if (location.pathname === '/basket') {
                this.subtractBonuses = this.bonusesValues;
                this.getBasket();
            }
            if (location.pathname === '/go-to-checkout') {
                this.subtractBonuses = this.bonusesValues;
                this.getBasketGoToCheckout();
            }
        } catch (e) {
            this.basketBonusesLoader = '';
            console.log('e', e);
        }
    };

    /**
     * Изменяем введенный промокод
     * @param e
     */
    @action promoCodeChangeValue = (e) => {
        try {
            this.promoCodeValues = e.target.value;
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Применяем/скидываем промокод
     * @param changeFlag
     * @returns {boolean}
     */
    @action promoCodeChange = (changeFlag = false) => {
        try {
            if (this.basketPromoCodeLoader === 'load') return false;

            if (changeFlag) {
                this.promoCodeErrMsg = '';
                this.promoCodeMsg = '';
                this.promo_id = null;
                this.promo_sum = 0;
                this.promo_type = null;
            } else {
                this.promo_id = this.promoCodeValues;
            }

            if (location.pathname === '/basket') {
                this.getBasket();
            }
            if (location.pathname === '/go-to-checkout') {
                this.getBasketGoToCheckout();
            }
        } catch (e) {
            this.basketPromoCodeLoader = '';
            console.log('e', e);
        }
    };

    /**
     * Контроль способов оплаты в зависимости от выбранного адреса доставки
     * @param element
     */
    @action controlPaymentMethods = (element = {}) => {
        try {
            const {haveCashless = 1, haveCash = 1} = element;

            if (this.valuePaymentMethod === 'byCash' && haveCash === 0) {
                this.valuePaymentMethod = 'online';
            } else if (this.valuePaymentMethod === 'bankCard' && haveCashless === 0) {
                this.valuePaymentMethod = 'online';
            }
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Выбор/Отмена экспресс доставки
     */
    changeExpressDelivery = (event) => {
        this.deliveryValue = event.target.value;

        if (this.deliveryValue === 'expressDelivery') {
            // Если был выбран способ оплаты при получении и выбрали экспресс доставку, сбрасываем на онлайн
            if (this.valuePaymentMethod === 'bankCard' || this.valuePaymentMethod === 'byCash') {
                this.valuePaymentMethod = 'online'
            }
            this.deliveryDate = this.minDateExpress;
            this.deliverySum = this.deliveryAmountExpress;
        } else {
            this.deliveryDate = this.minDate;
            this.deliverySum = this.deliveryAmount;
        }

        this.onlineAdd();
    };

    /**
     * Обработчик ввода сертификата
     * @param e
     */
    certificateChange = (e) => {
        try {
            // Стираем ошибку при вводе сертификата
            if (this.certificateErrorMessage !== '') {
                this.certificateErrorMessage = '';
            }

            this.certificateNumber = e.target.value;
        } catch (e) {
            console.log('e', e);
        }
    };

    /**
     * Поиск и применение сертификата, запрос на сервер
     * @param certificateNumber
     * @returns {Promise<void>}
     */
    applyCertificate = async (certificateNumber) => {
        try {
            if (isEmpty(certificateNumber)) {
                this.certificateErrorMessage = 'Введите сертификат!';
                return;
            }

            const el = this.certificateData.find(item => item.number === certificateNumber);
            if (el !== undefined) {
                this.certificateErrorMessage = 'Сертификат уже применен!';
                return;
            }

            if (this.finalSum === 0) {
                this.certificateErrorMessage = 'Невозможно применить сертификат!';
                return;
            }

            const values = {
                certificateNumber: certificateNumber
            };

            // Стучимся на сервак
            const response = await axios.post('/apply-certificate', values);

            const {success} = response.data;
            const {certificate} = success;

            this.certificateData.push(certificate);
            this.calculationPrice();
            this.certificateNumber = '';
            this.certificateErrorMessage = '';
        } catch (e) {
            console.log('e', e);
            this.certificateErrorMessage = e.response.data.message;
        }
    };

    /**
     * Удалить / отменить сертификат
     * @param certificate
     */
    deleteCertificate = (certificate) => {
        this.certificateData = this.certificateData.filter((el) => certificate.id !== el.id);
        this.calculationPrice();
    };

    /**
     * Вычитаем сумму сертификата из итоговой суммы заказа
     */
    calculationCertificate = () => {
        this.certificateSum = 0;
        if (this.certificateData.length === 0) {
            return;
        }

        let certificateSum = 0, writeSum = 0, finalSum = this.finalSum;
        this.certificateData = this.certificateData.map((el) => {
            if (finalSum !== 0) {
                writeSum = Math.min(el.balance, finalSum);
                finalSum = finalSum - writeSum;
                el.writeSum = writeSum;
            }
            certificateSum = certificateSum + writeSum;

            return el;
        });

        this.finalSum = finalSum;
        this.certificateSum = certificateSum;
    };

    /**
     * Отметить / Снять все флажки у товара
     * @param event
     */
    @action changeCheckboxAll = (event) => {
        this.updateCheckedBasket(event.target.checked);
    };

    /**
     * Отметить / Снять флажок у товара
     * @param event
     * @param productDataId
     */
    @action changeCheckboxOne = (event, productDataId) => {
        this.updateCheckedBasket(event.target.checked, productDataId);
    };

    /**
     * Обновление чек боксов, запрос на сервер
     * @param checked
     * @param productDataId
     * @returns {Promise<void>}
     */
    @action updateCheckedBasket = async (checked, productDataId = null) => {
        await axios.post('/update_checkbox_basket', {productDataId: productDataId, checked: checked});

        this.getBasket();
    }

    /**
     * Выбрать адрес доставки из списка доступных адресов пользователя
     * @param el
     */
    @action changeDeliveryAddress = (el) => {
        const values = {
            ...el,
            paymentMethod: this.valuePaymentMethod,
            // flagBonusesPromoCod: this.flagBonusesPromoCod,
            // promoCodeValue: this.promo_id,
            promoCodeValue: this.promoCodeValues,
            certificateData: this.certificateData,
            // deliveryData: this.deliveryData,
            bonuses: this.bonusesValues,
        };
        if (el && el.status && el.id !== this.currentDeliveryAddress?.id) {
            Inertia.post(route('changeDeliveryAddress'), values, {
                onSuccess: (page) => {
                    this.rootStore.modalStore.closeDialogDeliveryAddresses();
                },
                onError: () => {
                    //
                },
            });
        }
    }

    /**
     * Удалить адрес доставки из списка
     * @param event
     * @param el
     */
    @action deleteDeliveryAddress = (event, el) => {
        event.stopPropagation();
        const values = {
            ...el,
            paymentMethod: this.valuePaymentMethod,
            // flagBonusesPromoCod: this.flagBonusesPromoCod,
            // promoCodeValue: this.promo_id,
            promoCodeValue: this.promoCodeValues,
            certificateData: this.certificateData,
            // deliveryData: this.deliveryData,
            bonuses: this.bonusesValues,
        };
        Inertia.post(route('deleteDeliveryAddress'), values, {
            onSuccess: (page) => {
                //
            },
            onError: () => {
                //
            },
        });
    }

    /**
     * Скрыть выбранный адрес доставки
     */
    @action closeVisualAddress = () => {
        this.visualAddress = '';
    }
}
